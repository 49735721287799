import React from "react";
import styled from "styled-components";
import Divider from "../atoms/Divider";

const Container = styled.div`
  padding: 30px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 0.5em;
`;

const SubText = styled.p`
  font-size: 1rem;
  margin: 0.6em 0;
`;

interface PageTitleProps {
  title: string;
  subTitle?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const PageLayout: React.FC<PageTitleProps> = ({
  title,
  subTitle,
  style,
  children,
}) => {
  return (
    <Container style={{...style}}>
      <Title>{title}</Title>
      <Divider/>
      <SubText>{subTitle}</SubText>
      {children}
    </Container>
  );
};

export default PageLayout;
