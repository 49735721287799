import React from "react";
import styled, { keyframes } from "styled-components";
import DashboardLayout from "../Layout";

const rotate = keyframes`
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  @media (max-width: 768px) {
    top: 48%;
    right: 60%;
  }
`;

const LoadingDiv = styled.div`
  position: absolute;
  background: ${(props) => props.theme.section.hover};
  opacity: 1;
  border-radius: 50%;
  animation: ${rotate} 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`;

const Loading: React.FC<{isInner?: boolean}> = ({isInner}) => (
  <DashboardLayout isLoading={isInner}>
    <LoadingContainer>
      <LoadingDiv />
      <LoadingDiv style={{ animationDelay: "-0.7s" }} />
    </LoadingContainer>
  </DashboardLayout>
);

export default Loading;
