import React, { FC, ReactNode } from "react";
import styled from "styled-components";

const Div = styled.div<AlertProps>`
  @keyframes slideRight {
    from {
      opacity: 0;
      transform: translateX(-10px) scale(0.98);
    }
    to {
      opacity: 1;
      transform: translateX(0px) scale(1);
    }
  }

  animation: slideRight ease-in 0.3s;
  padding: 20px;
  background: ${(props) => (props.type === "success" ? "#73BE23" : "#fff0f3")};
  border-left: ${(props) =>
    props.type === "success" ? "5px solid #7ed321" : "5px solid #FFB3C0"};
  border-radius: 4px;
  font-size: 0.875rem;
  margin: 10px 0;
  color: ${(props) =>
    props.type === "success" ? "white" : props.theme.colors.text};
`;

interface AlertProps {
  title?: string;
  type?: string;
  children: ReactNode;
}

const Alert: FC<AlertProps> = (props) => {
  return (
    <Div type={props.type}>
      <summary style={{ fontWeight: 700 }}>{props.title}</summary>
      {props.children}
    </Div>
  );
};

export default Alert;
