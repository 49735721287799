import { COLORS } from "../../assets/theme";

const darkTheme = {
  themeType: "dark",

  colors: {
    background: COLORS.dark,
    text: COLORS.textTertiary,
  },

  section: {
    background: COLORS.sectionDark,
    hover: COLORS.primary,
    text: COLORS.textPrimary,
  },

  sideMenu: {
    background: COLORS.dark,
    text: COLORS.white,
  }
};

export default darkTheme;
