import React, { FC, ReactNode } from "react";
import styled from "styled-components";

const LabelStyle = styled.label`
  font-size: 0.875rem;
  display: block;

  & input {
    margin: 0.8rem 0;
  }
`;

interface LabelProps {
  style?: React.CSSProperties;
  children: ReactNode;
}

const Label: FC<LabelProps> = (props) => {
  return <LabelStyle style={{ ...props.style }}>{props.children}</LabelStyle>;
};

export default Label;
