import React, { useState } from "react";
import styled from "styled-components";
import { HttpService } from "../../services";
import { useNavigate } from "react-router-dom";
import DashboardPageLayout from "../../components/organism/HeaderLayout";
import Button from "../../components/atoms/Btn";
import Divider from "../../components/atoms/Divider";
import Input from "../../components/atoms/Input";
import Label from "../../components/atoms/Label";
import DashboardLayout from "../Layout";
import Toast from "../../components/atoms/Toast";
import Alert from "../../components/atoms/Alert";

const Container = styled.div`
  padding: 30px;
  min-height: 100vh;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 30px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 100px;
  gap: 14px;
`;

interface APIResponseProps {
  success?: boolean;
  id: number;
  category_name: string;
  created_by: string;
  message: string,
  error?: string;
}

const AddCategoryPage: React.FC = () => {
  const [title, setTitle] = useState<string>("");
  const [toast, setToast] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [response, setResponse] = useState<{
    message: string | undefined;
    type: boolean;
  }>({
    message: "",
    type: false,
  });
  const navigate = useNavigate();

  const titleValidate = (value: string): string | undefined => {
    if (!value.trim()) {
      return "Category is missing";
    }

    return undefined;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: string[] = [];
    const titleValidator = titleValidate(title);
    if (titleValidator) errors.push(titleValidator);

    const reqData = {
      category: title,
    };
    setFormErrors(errors);
    if (errors.length === 0) {
      try {
        const res = await HttpService.post<APIResponseProps>(
          "/category",
          reqData
        );

        if (res.data.success) {
          setResponse({
            message: "Category Added Successfully",
            type: false,
          });
          setToast(true);
          handleCancel();
        } else {
          setResponse({
            message: res.data.error,
            type: true,
          });
        }
      } catch (error: any) {
        setResponse({
          message: error.message,
          type: true,
        });
      }
    }
    setToast(true);
    setTitle("");
    setFormErrors([]);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <Container>
        <DashboardPageLayout heading="New Category" />
        <Divider />
        <FormContainer onSubmit={handleSubmit}>
          <Label style={{ width: "100%" }}>
            Title <span style={{ color: "red" }}> *</span>
            <Input
              type="text"
              maxLength={100}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Label>

          <ButtonContainer>
            <Button bgcolor="#333" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit">Add</Button>
          </ButtonContainer>
        </FormContainer>

        {formErrors.length > 0 && (
          <Alert title="Error">
            {formErrors.map((err: string, index: number) => (
              <div key={index}>{err}</div>
            ))}
          </Alert>
        )}
        {response.type ? (
          <Toast
            showToast={toast}
            setShowToast={setToast}
            type="error"
            content={response.message}
          />
        ) : (
          response.message && (
            <Toast
              showToast={toast}
              setShowToast={setToast}
              type="success"
              content={response.message}
            />
          )
        )}
      </Container>
    </DashboardLayout>
  );
};

export default AddCategoryPage;
