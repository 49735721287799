import React, { Suspense, useEffect, useReducer, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import AppRoutes from "./routes";
import { ErrorBoundary } from "react-error-boundary";
import Global from "./styles/theme/global";
import lightTheme from "./styles/theme/light";
import { CustomThemeContext } from "./context/ThemeContext";
import ThemeReducer from "./context/ThemeReducer";
import { ThemeProvider } from "styled-components";
import { ThemeProviderProps } from "./config/ThemeConfig";
import { ErrorPage, LoadingPage } from "./pages/Custom";
import { authService } from "./services/authService";
import TokenExpirationPopup from "./components/organism/TokenExpired";

const App = () => {
  const storedTheme = localStorage.getItem("theme");
  const initialTheme = storedTheme ? JSON.parse(storedTheme) : lightTheme;
  const [currentTheme, setNewTheme] = useReducer(ThemeReducer, {
    updatedTheme: initialTheme,
  });
  const themeContextProviderValue: ThemeProviderProps = {
    currentTheme,
    setNewTheme,
  };
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const isExpired = authService.isTokenExpired();
      setIsTokenExpired(isExpired);
    };

    checkTokenExpiration();

    const tokenExpirationInterval = setInterval(() => {
      checkTokenExpiration();
    }, 1000 * 60);

    return () => clearInterval(tokenExpirationInterval);
  }, []);

  const handleTokenExpirationClose = () => {
    setIsTokenExpired(false);
  };

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(currentTheme.updatedTheme));
  }, [currentTheme.updatedTheme]);

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <CustomThemeContext.Provider value={themeContextProviderValue}>
        <ThemeProvider theme={currentTheme.updatedTheme}>
          <Global />
          <Router>
            <Suspense fallback={<LoadingPage />}>
              {isTokenExpired ? (
                <TokenExpirationPopup onClose={handleTokenExpirationClose} />
              ) : (
                <AppRoutes />
              )}
            </Suspense>
          </Router>
        </ThemeProvider>
      </CustomThemeContext.Provider>
    </ErrorBoundary>
  );
};

export default App;