import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "../../components/atoms/Btn";
import coming_soon from "../../assets/images/under_construction.png";
import DashboardLayout from "../Layout";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.background};

  h3 {
    font-size: 2rem;
    margin-bottom: 8px;
    text-align: center;
  }
  img {
    width: 200px;
    margin-bottom: 16px;
    will-change: opacity;
    transform: translateZ(0);
    transition: opacity 0.25s linear;
  }
  p {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 36px;
    color: ${(props) => props.theme.colors.text};
  }
`;

const ComingSoonPage: React.FC = () => {
  return (
    <DashboardLayout>
      <Container>
        <img src={coming_soon} alt="page_under_construction" loading="lazy" />
        <h3>Coming Soon</h3>
        <p>Our website is under construction. Please check back later.</p>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <Button>Go to Home</Button>
        </Link>
      </Container>
    </DashboardLayout>
  );
};

export default ComingSoonPage;