import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Divider from "./Divider";

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0.8em;

  p {
    font-size: 1em;
    font-weight: bold;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: start;
    p {
      margin: 0.5em 0;
    }
  }
`;

const ContentContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;

  @media (min-width: 992px) {
    flex: 0 0 auto;
    width: 40%;
  }
`;

interface LabelProps {
  label?: string;
  children: ReactNode;
}

const ListItem: FC<LabelProps> = ({ label, children }) => {
  return (
    <>
    <Row>
      <p>{label}</p>
      <ContentContainer>{children}</ContentContainer>
    </Row>
    <Divider />
    </>
  );
};

export default ListItem;
