import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
// import { authService } from "../../services/authService";
import { emailValidate } from "../../utils";
import { COLORS, SPACING } from "../../assets/theme";
import Alert from "../../components/atoms/Alert";
import Button from "../../components/atoms/Btn";
import Divider from "../../components/atoms/Divider";
import Input from "../../components/atoms/Input";
import Label from "../../components/atoms/Label";
import { FaPlusCircle } from "react-icons/fa";
import AuthLayout from "./AuthLayout";
import SocailAuth from "../../components/atoms/SocialAuth";
import { authService } from "../../services/authService";
import Loader from "../../components/atoms/Loader";

const CardContainer = styled.div`
  animation: slideLeft ease-in 0.3s;
  animation-name: slideLeft;
  from {
    opacity: 0;
    transform: translateX(30px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  box-shadow: 0 2px 20px 3px rgba(0, 0, 0, 0.06);
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  width: 410px;
  padding: 2rem;
  position: relative;
`;

const ForgotLink = styled(Link)`
  display: flex;
  justify-content: end;
  color: ${(props) => props.theme.colors.text};
  text-decoration: none;
  font-weight: 500;
  font-size: 1em;
  &:hover {
    color: ${(props) => props.theme.section.hover};
    text-decoration: underline;
  }
`;

const Heading = styled.h1`
  color: ${(props) => props.theme.colors.text};
  font-weight: 600;
  font-size: 1.6em;
  margin: ${SPACING.s}px 0;
`;

interface LoginProps {
  success: boolean;
  message: string;
  status_response?: string;
  token_type?: string;
}

const LoginPage: FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const redirectToRegistration = () => {
    navigate("/register");
  };

  const passwordValidate = (value: string) => {
    if (!value || value.length < 6)
      return "Password must be more than 6 characters";
    return undefined;
  };

  const loginSubmitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    let errors = [];
    let emailCheck = emailValidate(email);
    if (emailCheck) errors.push(emailCheck);

    let passwordCheck = passwordValidate(password);
    if (passwordCheck) errors.push(passwordCheck);
    setFormErrors(errors);
    if (!errors.length) {
      setLoading(true);
      const res = await authService.login(email, password) as LoginProps;
      if (res && res.success) {
        navigate("/");
      } else {
        setFormErrors([res.message]);
        setEmail("");
        setPassword("");
      }
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <CardContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 100,
            marginBottom: "25px",
          }}
        >
          <Link style={{ textDecoration: "none", color: "gray", fontSize: "1.2rem" }} to={"https://streamingly.net/"}>Streamingly</Link>
        </div>

        <Heading>Log in</Heading>

        <div className="form">
          <form onSubmit={loginSubmitHandler}>
            {formErrors.length ? (
              <Alert title="Failed to login">
                {formErrors.map((err: string) => (
                  <div>{err}</div>
                ))}
              </Alert>
            ) : (
              ""
            )}
            <Label>
              <span>Email</span>
              <Input
                type="email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Label>

            <Label>
              <span>Password</span>
              <Input
                type="password"
                isSecure={true}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Label>

            <ForgotLink to={'/reset-password'}>Forgot password?</ForgotLink>

            <div style={{ marginTop: "10px" }}>
              <Button fullwidth type="submit">
                {isLoading ? <Loader /> : "Log in" } 
              </Button>
            </div>
          </form>
        </div>

        <Divider isText />
        <Button
          fullwidth
          bgcolor={COLORS.secondary}
          onClick={redirectToRegistration}
          iconLeft={<FaPlusCircle />}
        >
          Create account
        </Button>
        <SocailAuth />
      </CardContainer>
    </AuthLayout>
  );
};

export default LoginPage;
