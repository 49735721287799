import React, { FC, ReactNode } from "react";
import styled from "styled-components";

const ButtonStyle = styled.button<ButtonProps>`
  display: flex;
  width: ${(props) => (props.fullwidth ? "100%" : "auto")};
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background: ${(props) => props.bgcolor || props.theme.section.hover};
  border: none;
  border-radius: 0.25rem;
  color: ${(props) => props.color};
  outline: none;
  font-size: .9em;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, border-color 0.3s;

  &:hover {
    background: ${(props) => props.hoverColor};
  }

  &:active {
    background: ${(props) => props.activeColor || props.bgcolor};
  }
`;

const IconStyle = styled.span`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`;

interface ButtonProps {
  onClick?: () => void;
  fullwidth?: boolean;
  type?: "button" | "submit" | "reset";
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  color?: string;
  bgcolor?: string;
  hoverColor?: string;
  focusColor?: string;
  activeColor?: string;
  outline?: boolean;
  children: ReactNode;
}

const Button: FC<ButtonProps> = ({
  color = "#fff",
  bgcolor,
  hoverColor,
  focusColor,
  activeColor,
  fullwidth,
  onClick,
  type = "button",
  iconLeft,
  iconRight,
  outline,
  children,
}) => {
  return (
    <ButtonStyle
      color={color}
      bgcolor={bgcolor}
      hoverColor={hoverColor}
      focusColor={focusColor}
      activeColor={activeColor}
      fullwidth={fullwidth}
      onClick={onClick}
      type={type}
      outline={outline}
    >
      {iconLeft && <IconStyle>{iconLeft}</IconStyle>}
      {children}
      {iconRight && <IconStyle>{iconRight}</IconStyle>}
    </ButtonStyle>
  );
};

export default Button;
