import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import MiddlewareRoute from "../middlewares";

const AppRoutes: FC = () => {
  return (
    <Routes>
      {routes?.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.requireAuth ? (
              <MiddlewareRoute requireAuth element={route.element} />
            ) : (
              route.element
            )
          }
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
