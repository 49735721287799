import { COLORS } from "../../assets/theme";

const lightTheme = {
  themeType: "light",

  colors: {
    background: COLORS.background,
    text: COLORS.textPrimary,
  },

  section: {
    background: COLORS.white,
    hover: COLORS.primary,
    text: COLORS.textPrimary,
  },

  sideMenu: {
    background: COLORS.tertiary,
    text: COLORS.white,
  }
};

export default lightTheme;
