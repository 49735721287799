export const COLORS = {
  primary: "#FF6F3C",
  secondary: "#155263",
  tertiary: "#04223e",
  textPrimary: "#155263",
  textTertiary: "#FF9A3C",
  dark: "#191a23",
  sectionDark: "#21232e",
  success: "#22bb33",
  warn: "#ff9800",
  info: "#2196f3",
  error: "#ff3333",
  custom: "#2f2d2d",
  textGrey: "",
  textGreen: "green",
  textBlue: "blue",
  textRed: "red",
  disable: "#e6e6e6",
  hoverColor: "",
  borderColor: "#ced4da",
  backgroundColor: "",
  white: "#FFFFFF",
  background: "#f6f6f6",
  lightWhite: "#f5f5f5",
  footerColor: "#151414",
  black: "#000000",
  grey: "#808080",
  placeHolder: "#b4bdc5",
} as const;

export const SPACING = {
  xs: 10,
  s: 12,
  m: 16,
  l: 20,
  xl: 24,
  xxl: 36,
};
