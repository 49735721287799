import React, { FC } from "react";
import styled from "styled-components";
import Divider from "./Divider";

const TextStyle = styled.label`
  font-size: 1.3em;
  font-weight: bold;
  margin: 0.8rem 0;
`;

interface LabelProps {
  text: string;
}

const Heading: FC<LabelProps> = ({ text }) => {
  return (
    <>
      <TextStyle>{text}</TextStyle>
      <Divider />
    </>
  );
};

export default Heading;
