import React from 'react';
import styled from 'styled-components';
import Button from '../atoms/Btn';
import Label from '../atoms/Label';

interface TokenExpirationPopupProps {
  onClose: () => void;
}

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5.5rem;
  border-radius: 0.3rem;
  background: ${(props) => props.theme.section.background};
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const TokenExpirationPopup: React.FC<TokenExpirationPopupProps> = ({ onClose }) => (

  
  <PopupContainer>
    <h3>Session Expired</h3>
    <Label>Token has expired. Please log in again.</Label>
    <Button fullwidth onClick={onClose}>Ok</Button>
  </PopupContainer>
);

export default TokenExpirationPopup;