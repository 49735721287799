import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "../utils";

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

apiService.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    const token = localStorage.getItem("bearer");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const HttpService = {
  get: async <T,>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    return apiService.get(url, config);
  },

  post: async <T,>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig & { onUploadProgress?: (progressEvent: ProgressEvent) => void }
  ): Promise<AxiosResponse<T>> => {
    return apiService.post(url, data, config);
  },

  put: async <T,>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    return apiService.put(url, data, config);
  },

  del: async <T,>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    return apiService.delete(url, config);
  },
};