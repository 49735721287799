import React, { useEffect, ReactNode, SetStateAction } from "react";
import styled from "styled-components";
import { COLORS } from "../../assets/theme/index";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaTimes,
  FaInfoCircle,
  FaExclamationTriangle,
} from "react-icons/fa";

interface ToastProps {
  type: "success" | "warning" | "error" | "info" | "custom";
}

const getColorStyle = (type: ToastProps["type"]) => {
  switch (type) {
    case "success":
      return COLORS.success;
    case "warning":
      return COLORS.warn;
    case "error":
      return COLORS.error;
    case "info":
      return COLORS.info;
    case "custom":
      return COLORS.custom;
    default:
      return COLORS.secondary;
  }
};

const ToastContainer = styled.div<ToastProps>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: start;
  gap: 1em;
  background-color: ${({ type }) => getColorStyle(type)}20;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  z-index: 99;

  .icon-size {
    width: 26px;
    height: 26px;
  }

  .icon {
    color: ${({ type }) => getColorStyle(type)};
  }
`;

const Column = styled.div`
  max-width: 285px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
  }
`;

const Toast: React.FC<{
  type: "success" | "warning" | "error" | "info" | "custom";
  content?: string;
  title?: string;
  children?: ReactNode;
  showToast: boolean;
  setShowToast: React.Dispatch<SetStateAction<boolean>>;
}> = ({ type, title, content, children, showToast, setShowToast }) => {
  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [setShowToast, showToast]);

  const closeToastHandler = () => {
    setShowToast(false);
  };

  const getIcon = () => {
    const IconComponent = {
      success: FaCheckCircle,
      warning: FaExclamationTriangle,
      error: FaExclamationCircle,
      info: FaInfoCircle,
      custom: FaInfoCircle,
    }[type];

    return <IconComponent className="icon icon-size" />;
  };

  return (
    <ToastContainer type={type} style={{ display: showToast ? "" : "none" }}>
      {children ? (
        children
      ) : (
        <>
          {getIcon()}
          <Column>
            <span className="title">{title || type}</span>
            <p>{content || ""}</p>
          </Column>
        </>
      )}

      <FaTimes className="icon" onClick={closeToastHandler} />
    </ToastContainer>
  );
};

export default Toast;
