import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SPACING } from "../../assets/theme";
import AuthLayout from "./AuthLayout";
import Button from "../../components/atoms/Btn";
import Alert from "../../components/atoms/Alert";
import Input from "../../components/atoms/Input";
import Label from "../../components/atoms/Label";
import { authService } from "../../services/authService";

const CardContainer = styled.div`
  animation: slideRight ease-in 0.3s;
  box-shadow: 0 2px 20px 3px rgba(0, 0, 0, 0.06);
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  width: 410px;
  padding: 2rem;
  position: relative;

  @keyframes slideRight {
    from {
      opacity: 0;
      transform: translateX(-30px) scale(0.98);
    }
    to {
      opacity: 1;
      transform: translateX(0px) scale(1);
    }
  }
`;

const Heading = styled.h1`
  color: ${(props) => props.theme.colors.text};
  font-weight: 600;
  font-size: 1.6em;
  margin: ${SPACING.s}px 0;
`;

interface OtpProps {
  username: string;
  pwd: string;
}

interface APIResponse {
  success?: boolean;
  message: string;
  status_response?: string;
  token_type: string;
  access_token: string;
}

const OTPVerification: FC<OtpProps> = ({ username, pwd }) => {
  const navigate = useNavigate();
  const [otp, setOTP] = useState<string>("");
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const registrationSubmitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors: string[] = [];
    if (otp.length < 6) {
      errors.push("Invalid Code");
    }

    setFormErrors(errors);
    if (errors.length === 0 && otp) {
      const res = await authService.otp(username, pwd, otp) as APIResponse;
      if (res && res.success) {
        localStorage.setItem(res.token_type, res.access_token)
        navigate("/");
      } else {
        setFormErrors([res.message])
        setOTP("");
      }
    }
  };

  return (
    <AuthLayout>
      <CardContainer>
        <Heading>Verify Code</Heading>
        <div className="form">
          <form onSubmit={registrationSubmitHandler}>
            {formErrors.length > 0 && (
              <Alert title="OTP Verification failed">
                {formErrors.map((err: string, index) => (
                  <div key={index}>{err}</div>
                ))}
              </Alert>
            )}

            <Label>
              <span>Enter your verification code</span>
              <Input
                type="text"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
              />
            </Label>

            <div style={{ margin: "10px 0" }}>
              <Button type="submit" fullwidth>
                Verify
              </Button>
            </div>
          </form>
        </div>
      </CardContainer>
    </AuthLayout>
  );
};

export default OTPVerification;
