import React, { FC, ReactNode } from "react";
import styled from "styled-components";

const LoginDiv = styled.div`
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.section.text};;
`;

interface LoginLayoutProps {
  children: ReactNode;
}

const AuthLayout: FC<LoginLayoutProps> = (props) => {
  return <LoginDiv>{props.children}</LoginDiv>;
};

export default AuthLayout;