import { Configuration } from '@azure/msal-browser';

/* eslint-disable no-control-regex */
const env = process.env;
const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const API_BASE_URL = env.REACT_APP_BASE_URL;
export const USR_KEY = env.REACT_APP_API_KEY;
export const CLINET_ID = env.REACT_APP_GOOGLE_ID;
export const msalConfig: Configuration = {
  auth: {
    clientId: env.REACT_APP_MS_ID as string,
    // authority: `https://login.microsoftonline.com/${env.REACT_APP_MS_TENANT_ID}`,
    // redirectUri: "/",
  },
  // cache: {
  //   cacheLocation: "sessionStorage", // This configures where your cache will be stored
  //   storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  // },
};
export const isEmptyString = (str: string) => {
  return (!str || str.length === 0);
}

export const emailValidate = (value: string): string | undefined => {
  if (!emailRegex.test(value)) return "Invalid email format";
  return undefined;
};

export const getAuthToken = () => {
  return localStorage.getItem("bearer");
}

export const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
}

export const toTitleCase = (str: string) => {
  if (!str)
    return '';

  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const decodeJWT = (token: string) => {
  try {
    const decoded = JSON.parse(atob(token.split(".")[1]));
    return decoded;
  } catch (error) {
    console.error("Error decoding JWT token:", error);
    return {};
  }
};

export const convertBytes = (bytes: number) => {
  let l = 0,
    n = parseInt(bytes.toString(), 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const retry = async (
  importFunc: () => Promise<any>,
  retries: number = 3
): Promise<any> => {
  try {
    return await importFunc();
  } catch (error) {
    if (retries > 0) {
      console.error(
        `Failed to import module. Retrying... (${error} retries left)`
      );
      return retry(importFunc, retries - 1);
    }
    throw error;
  }
};
