import { lazy } from "react";
import { retry } from "../utils";
import Home from "../pages/Home";
import { LoginPage, ResetPassword, SignUpPage } from "../pages/Auth";
import AddCategoryPage from "../pages/AddCategory";
import AccountDetail from "../pages/Account/AccountDetails";
import ChangePassword from "../pages/Account/ChangePassword";

const SettingPage = lazy(() => retry(() => import("../pages/Setting")));
const AddVideoPage = lazy(() => retry(() => import("../pages/AddVideos")));
const ComingSoonPage = lazy(() =>
  retry(() => import("../pages/Custom/ComingSoon"))
);
const NotFoundPage = lazy(() =>
  retry(() => import("../pages/Custom/NotFound"))
);
const ContactPage = lazy(() => retry(() => import("../pages/Contact")));
const VideoPage = lazy(() => retry(() => import("../pages/Videos")));
const CategoryPage = lazy(() => retry(() => import("../pages/Categories")));

export const routes = [
  {
    path: "/register",
    element: <SignUpPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/change-password",
    requireAuth: true,
    element: <ChangePassword />,
  },
  {
    path: "/",
    requireAuth: true,
    element: <Home />,
  },
  {
    path: "/videos",
    requireAuth: true,
    element: <VideoPage />,
  },
  {
    path: "/video/new",
    requireAuth: true,
    element: <AddVideoPage />,
  },
  {
    path: "/category",
    requireAuth: true,
    element: <CategoryPage />,
  },
  {
    path: "/category/new",
    requireAuth: true,
    element: <AddCategoryPage />,
  },
  {
    path: "/support",
    requireAuth: true,
    element: <ContactPage />,
  },
  {
    path: "/metrics",
    requireAuth: true,
    element: <ComingSoonPage />,
  },
  {
    path: "/updates",
    requireAuth: true,
    element: <ComingSoonPage />,
  },
  {
    path: "/support",
    requireAuth: true,
    element: <ContactPage />,
  },
  {
    path: "/settings",
    requireAuth: true,
    element: <SettingPage />,
  },
  {
    path: "/account",
    requireAuth: true,
    element: <AccountDetail />,
  },
  {
    path: "/",
    requireAuth: true,
    element: <ComingSoonPage />,
  },
  {
    path: "*",
    requireAuth: true,
    element: <NotFoundPage />,
  },
];
