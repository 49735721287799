import React, { FC } from "react";
import styled from "styled-components";

const LoaderStyle = styled.div`
    border: 10px solid #f3f3f3;
    border-top: 10px solid #155263;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


const Loader: FC = (props) => {
  return <LoaderStyle></LoaderStyle>;
};

export default Loader;