import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { emailValidate } from "../../utils";
import { COLORS, SPACING } from "../../assets/theme";
import AuthLayout from "./AuthLayout";
import Button from "../../components/atoms/Btn";
import Alert from "../../components/atoms/Alert";
import Divider from "../../components/atoms/Divider";
import Input from "../../components/atoms/Input";
import Label from "../../components/atoms/Label";
import SocailAuth from "../../components/atoms/SocialAuth";
import { authService } from "../../services/authService";
import OTPVerification from "./otpVerficiation";
import Loader from "../../components/atoms/Loader";

const CardContainer = styled.div`
  animation: slideRight ease-in 0.3s;
  box-shadow: 0 2px 20px 3px rgba(0, 0, 0, 0.06);
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  width: 410px;
  padding: 2rem;
  position: relative;

  @keyframes slideRight {
    from {
      opacity: 0;
      transform: translateX(-30px) scale(0.98);
    }
    to {
      opacity: 1;
      transform: translateX(0px) scale(1);
    }
  }
`;

const Heading = styled.h1`
  color: ${(props) => props.theme.colors.text};
  font-weight: 600;
  font-size: 1.6em;
  margin: ${SPACING.s}px 0;
`;
interface RegisterProps {
  success: boolean;
  message: string;
  status_response?: string;
}

const SignUpPage: FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSuccessed, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const backToLogin = () => {
    navigate("/login");
  };

  const passwordValidate = (value: string): string | undefined => {
    if (!value || value.length < 8)
      return "Password must be at least 8 characters long";
    return undefined;
  };

  const repeatValidate = (val1: string, val2: string): string | undefined =>
    val1 !== val2 ? "Passwords do not match" : undefined;

  const registrationSubmitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors: string[] = [];
    const emailCheck = emailValidate(email);
    if (emailCheck) errors.push(emailCheck);

    const passwordCheck = passwordValidate(password);
    if (passwordCheck) errors.push(passwordCheck);

    const repeatCheck = repeatValidate(password, repeatPassword);
    if (repeatCheck) errors.push(repeatCheck);

    setFormErrors(errors);
    if (errors.length === 0) {
      setLoading(true);
      const res = (await authService.register(
        email,
        password
      )) as RegisterProps;
      if (res && res.success) {
        setSuccess(res.success);
      } else {
        setFormErrors([res.message])
        setEmail("");
        setPassword("");
        setRepeatPassword("");
      }
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      {!isSuccessed && (
        <CardContainer>
          <Heading>Sign Up</Heading>
          {
            <div className="form">
              <form onSubmit={registrationSubmitHandler}>
                {formErrors.length > 0 && (
                  <Alert title="Registration failed">
                    {formErrors.map((err: string, index) => (
                      <div key={index}>{err}</div>
                    ))}
                  </Alert>
                )}

                <Label>
                  <span>Email</span>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Label>

                <Label>
                  <span>Password</span>
                  <Input
                    type="password"
                    isSecure={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Label>

                <Label>
                  <span>Repeat password</span>
                  <Input
                    type="password"
                    isSecure={true}
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                  />
                </Label>

                <div style={{ marginTop: "10px" }}>
                  <Button type="submit" fullwidth>
                    {isLoading ? <Loader/> : "Create account"}
                  </Button>
                  <Divider isText />
                </div>
              </form>
            </div>
          }
          <Button fullwidth onClick={backToLogin} bgcolor={COLORS.secondary}>
            Back to log in
          </Button>
          <SocailAuth />
        </CardContainer>
      )}
      {isSuccessed && <OTPVerification username={email} pwd={password} />}
    </AuthLayout>
  );
};

export default SignUpPage;
