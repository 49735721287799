import { HttpService } from ".";
import { decodeJWT, getAuthToken } from "../utils";

interface SignupProps {
  success: boolean;
  message: string;
  status_response?: string;
}

interface GoogleProps {
  authUrl: string;
}

interface LoginProps {
  success?: boolean;
  message?: string;
  status_response?: string;
  token_type: string;
  access_token: string;
}

export const authService = {
  register: async (email: string, password: string) => {
    try {
      const res = await HttpService.post<SignupProps>("/register", {
        email: email,
        password: password,
      });
      return res.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  login: async (username: string, password: string) => {
    try {
      const res = await HttpService.post<LoginProps>("/login", {
        email: username,
        password: password,
        login_type: "n",
      });
      if (res.data)
        localStorage.setItem(res.data.token_type, res.data.access_token);
      return res.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  otp: async (username: string, password: string, otp: string) => {
    try {
      const res = await HttpService.post<LoginProps>("/verify_user", {
        email: username,
        password: password,
        login_type: "n",
        otp: otp,
      });
      if (res.data)
        localStorage.setItem(res.data.token_type, res.data.access_token);
      return res.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  reset: async (username: string, password: string, stage: string) => {
    try {
      const res = await HttpService.post<LoginProps>("/forget_password", {
        email: username,
        password: password,
        forget_stage: stage,
      });
      return res.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  googleLogin: async () => {
    try {
      const res = await HttpService.get<GoogleProps>("/g_login");
      if (res) return res.data;
    } catch (er) {
      console.log(er);
      return er;
    }
  },
  logout: () => {
    localStorage.clear();
  },
  isAuthenticated: () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("access_token");
    const success = params.get("success");
    // const newUrl = window.location.href.split("?")[0];
    if (token && success) {
      localStorage.setItem("bearer", token);
      // window.location.href = newUrl;
      const decodedToken = decodeJWT(token);
      const expirationTime = decodedToken.exp * 1000;
      if (expirationTime > Date.now()) {
      } else {
        localStorage.clear();
        return false;
      }
    }
    const storedToken = getAuthToken();
    if (storedToken) {
      const storedDecodedToken = decodeJWT(storedToken);
      const storedExpirationTime = storedDecodedToken.exp * 1000;
      if (storedExpirationTime > Date.now()) {
        return true;
      } else {
        localStorage.clear();
        return false;
      }
    }
    return false
  },

  isTokenExpired: () => {
    const storedToken = getAuthToken();

    if (storedToken) {
      const storedDecodedToken = decodeJWT(storedToken);
      const storedExpirationTime = storedDecodedToken.exp * 1000;
      if (storedExpirationTime > Date.now()) {
        return false;
      } else {
        localStorage.clear();
        return true;
      }
    }
    return false;
  },
};
