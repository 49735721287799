import React from "react";
import { ShimmerTitle } from "react-shimmer-effects";
import styled from "styled-components";
import { COLORS } from "../../assets/theme";

const CardContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin: 16px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CardHeader = styled.div`
  background-color: ${props => props.theme.colors.text};
  padding: 10px;
  display: flex;
  align-items: center;
`;

const CardIcon = styled.div`
  margin-right: 10px;
  color: ${COLORS.white};
`;

const CardTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${COLORS.white};
`;

const CardBody = styled.div`
  padding: 20px;
`;

const CardValue = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

interface CardProps {
  title: string;
  value: number;
  icon: JSX.Element;
}

const CustomCard: React.FC<CardProps> = ({ title, value, icon }) => {
  return (
    <CardContainer>
      <CardHeader>
        <CardIcon>{icon}</CardIcon>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardBody>
        {value !== -1 ? <CardValue>{value}</CardValue> : <ShimmerTitle line={1} gap={10} variant="secondary" />}
      </CardBody>
    </CardContainer>
  );
};

export default CustomCard;
