import React, { FC } from "react";
import styled from "styled-components";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/authService";

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 1em 0;
`;

const SocialButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  .social-icon {
    height: 26px;
    width: 26px;
    margin-right: 10px;
  }

  .social-text {
    font-size: 16px;
    font-weight: 500;
  }
`;

interface SocailAuthProps {}

interface GoogleResponse {
  authUrl: string & Location;
}

const SocailAuth: FC<SocailAuthProps> = (props) => {
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    const res = (await authService.googleLogin()) as GoogleResponse;
    if (res && res.authUrl) window.location.href = res.authUrl;
    else navigate("/");
  };

  return (
    <SocialContainer>
      <SocialButton onClick={handleGoogleLogin}>
        <FcGoogle className="social-icon" />
        <span className="social-text">Continue with Google</span>
      </SocialButton>
    </SocialContainer>
  );
};

export default SocailAuth;
