import React, { FC, useState } from "react";
import styled from "styled-components";
import DashboardLayout from "../Layout";
import PageLayout from "../../components/organism/PageLayout";
import ListItem from "../../components/atoms/ListItem";
import Input from "../../components/atoms/Input";
import Heading from "../../components/atoms/Heading";
import Button from "../../components/atoms/Btn";
import { COLORS } from "../../assets/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.1rem;
  border-radius: 10px;
  margin: 2em 0;
  background: ${(props) => props.theme.section.background};
`;

const AccountDetail: FC = (props) => {
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");

  return (
    <DashboardLayout>
      <PageLayout title="Account">
        <Container>
            <Heading text="Personal Details"/>
          <ListItem label="First name">
            <Input
              placeholder="First Name"
              type="text"
              value={fname}
              onChange={(e: any) => setFName(e.target.value)}
            />
          </ListItem>
          <ListItem label="Last name">
            <Input
              placeholder="Last Name"
              type="text"
              value={lname}
              onChange={(e: any) => setLName(e.target.value)}
            />
          </ListItem>
          <ListItem label="Email">
            <Input
              placeholder="Email"
              type="email"
              value={lname}
              onChange={(e: any) => setLName(e.target.value)}
            />
          </ListItem>
          <div style={{marginTop: "2em"}}>
          <Button bgcolor={COLORS.secondary}>Update Account Details</Button>
          </div>
        </Container>
      </PageLayout>
    </DashboardLayout>
  );
};

export default AccountDetail;
