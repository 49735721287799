import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { COLORS } from "../../assets/theme";

const SideMenuContainer = styled.div<{ visible: boolean }>`
  background-color: ${(props) => props.theme.sideMenu.background};
  transition: width 0.3s;
  height: 100%;
  border-right: 1px solid ${COLORS.grey};

  @media (max-width: 890px) {
    width: 250px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(${(props) => (props.visible ? "0" : "-100%")});
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 100px;
  flex-wrap: wrap;
  h2 {
    color: ${(props) => props.theme.section.hover};
  }
`;

const Logo = styled.img`
  width: 70px;
  padding: 10px 5px;
  will-change: opacity;
  transform: translateZ(0);
  transition: opacity .25s linear;

  @media (max-width: 89px) {
    width: 30px;
    padding: 10px 4px;
  }
`;

const MenuHeading = styled.div`
  padding: 10px;
  margin: 10px 0;
  font-weight: bold;
  color: ${COLORS.grey};
`;

const MainMenuItem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 14px;
  border-bottom: 1px solid #444;
  cursor: pointer;
  color: ${(props) => props.theme.sideMenu.text};
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.section.hover};
    color: ${(props) => props.theme.colors.background};
  }
`;

interface MenuItemProps {
  icon?: React.ReactNode;
  text?: string;
  active?: boolean;
  path: string;
  onMenuClick: () => void;
}

const MenuItem: FC<MenuItemProps> = ({
  icon,
  text,
  active,
  path,
  onMenuClick,
}) => {
  const absolutePath = `/${path}`;
  return (
    <MainMenuItem
      className={active ? "active" : ""}
      to={absolutePath}
      onClick={onMenuClick}
    >
      {icon}
      {text}
    </MainMenuItem>
  );
};

interface SideMenuProps {
  sections?: MenuSection[];
  visible: boolean;
  setVisible: () => void;
}

interface MenuSection {
  heading: string;
  menuItems: MenuItemData[];
}

interface MenuItemData {
  icon: React.ReactNode;
  text: string;
  path: string;
  active?: boolean;
}

const SideMenu: FC<SideMenuProps> = ({ sections, visible, setVisible }) => {
  const sideMenuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        sideMenuRef.current &&
        !sideMenuRef.current.contains(event.target as Node)
      ) {
        setVisible();
      }
    };

    if (visible) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [visible, setVisible]);
  return (
    <SideMenuContainer ref={sideMenuRef} visible={visible as boolean}>
      <LogoContainer>
        <Logo src={logo} alt="Logo" loading="lazy" />
        <h2>Streamingly</h2>
      </LogoContainer>
      {sections?.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          {section.heading && <MenuHeading>{section.heading}</MenuHeading>}
          {section.menuItems.map((item, itemIndex) => (
            <MenuItem
              key={itemIndex}
              icon={item.icon}
              text={item.text}
              path={item.path}
              active={item.active}
              onMenuClick={setVisible}
            />
          ))}
        </div>
      ))}
    </SideMenuContainer>
  );
};

export default SideMenu;
