import React, { FC } from "react";
import { COLORS } from "../../assets/theme";

interface DividerProps {
  isText?: boolean;
}

const Divider: FC<DividerProps> = ({ isText }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{ height: "1px", width: "100%", background: COLORS.borderColor }}
      ></div>
      {isText ? (
        <p style={{ margin: "10px", fontWeight: 100, color: COLORS.grey }}>OR</p>
      ) : (
        <></>
      )}
      <div
        style={{ height: "1px", width: "100%", background: "#d1d5db" }}
      ></div>
    </div>
  );
};

export default Divider;
