import React, { FC, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { emailValidate } from "../../utils";
import { COLORS, SPACING } from "../../assets/theme";
import AuthLayout from "./AuthLayout";
import Button from "../../components/atoms/Btn";
import Alert from "../../components/atoms/Alert";
import Input from "../../components/atoms/Input";
import Label from "../../components/atoms/Label";
import { authService } from "../../services/authService";
import Loader from "../../components/atoms/Loader";

const CardContainer = styled.div`
  animation: slideRight ease-in 0.3s;
  box-shadow: 0 2px 20px 3px rgba(0, 0, 0, 0.06);
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  width: 410px;
  padding: 2rem;
  position: relative;

  @keyframes slideRight {
    from {
      opacity: 0;
      transform: translateX(-30px) scale(0.98);
    }
    to {
      opacity: 1;
      transform: translateX(0px) scale(1);
    }
  }
`;

const Heading = styled.h1`
  color: ${(props) => props.theme.colors.text};
  font-weight: 600;
  font-size: 1.6em;
  margin: ${SPACING.s}px 0;
`;

interface APIResponse {
  success?: boolean;
  message: string;
  status_response?: string;
  token_type?: string;
}

const RestPassword: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [response, setResponse] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mode, setMode] = useState<"email" | "password">("email");
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const backToLogin = () => {
    navigate("/login");
  };

  const passwordValidate = (value: string): string | undefined => {
    if (!value || value.length < 8)
      return "Password must be at least 8 characters long";
    return undefined;
  };

  const repeatValidate = (val1: string, val2: string): string | undefined =>
    val1 !== val2 ? "Passwords do not match" : undefined;

  const resetSubmitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors: string[] = [];
    const emailCheck = emailValidate(email);
    if (emailCheck) errors.push(emailCheck);

    if (mode === "password") {
      const passwordCheck = passwordValidate(newPassword);
      if (passwordCheck) errors.push(passwordCheck);

      const repeatCheck = repeatValidate(newPassword, confirmPassword);
      if (repeatCheck) errors.push(repeatCheck);
    }

    setFormErrors(errors);
    if (errors.length === 0) {
      if (mode === "email") {
        setLoading(true);
        const res = (await authService.reset(
          email,
          newPassword,
          "first"
        )) as APIResponse;
        if (res && res.success) setResponse("WE'VE JUST SENT YOU AN EMAIL TO RESET YOUR PASSWORD.");
        else setFormErrors([res.message])
      } else {
        setLoading(true);
        const res = (await authService.reset(
          email,
          newPassword,
          "second"
        )) as APIResponse;
        if (res && res.success) setResponse("Password Changed Successfully!");
        else setFormErrors([res.message])
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    const otp = searchParams.get("otp");
    if (email && otp) {
      setMode("password");
      setEmail(email);
    }
  }, [location.search]);

  return (
    <AuthLayout>
      <CardContainer>
        <Heading>Reset password</Heading>
        {!response ? (
          <div className="form">
            <form onSubmit={resetSubmitHandler}>
              {formErrors.length > 0 && (
                <Alert title="Reset Password failed">
                  {formErrors.map((err: string, index) => (
                    <div key={index}>{err}</div>
                  ))}
                </Alert>
              )}

              {mode === "email" ? (
                <Label>
                  <span>Email</span>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Label>
              ) : (
                <Label style={{ marginBottom: "2rem" }}>
                  Enter a new password for
                  <span style={{ paddingLeft: "0.3rem", fontWeight: "bold" }}>
                    {email}
                  </span>
                </Label>
              )}
              {mode === "password" && (
                <>
                  <Label>
                    <span>New Password</span>
                    <Input
                      type="password"
                      isSecure={true}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Label>

                  <Label>
                    <span>Confirm password</span>
                    <Input
                      type="password"
                      isSecure={true}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Label>
                </>
              )}

              <div style={{ margin: "10px 0" }}>
                <Button type="submit" fullwidth>
                  {isLoading ? <Loader/> : "Reset Password"}
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <Alert type="success" >{response}</Alert>
        )}
        <Button
          fullwidth
          onClick={backToLogin}
          bgcolor="transparent"
          color={COLORS.secondary}
        >
          Back to log in
        </Button>
      </CardContainer>
    </AuthLayout>
  );
};

export default RestPassword;
