import React, { FC, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import {
  FaPlayCircle,
  FaHome,
  FaBell,
  FaRegChartBar,
  FaHeadset,
} from "react-icons/fa";
import SideMenu from "../components/organism/SideMenu";
import Header from "../components/organism/Header";
import { FaGears } from "react-icons/fa6";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  overflow-x: auto;
`;

const FixedSidebar = styled.div`
  flex: 0 0 15%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  width: 16%;
  min-height: 100vh;
  z-index: 2;

  &.overlay-visible {
    filter: blur(4px);
  }

  @media (max-width: 890px) {
    flex: 0 0 0%;
    width: 0%;
    transition: width 0.3s ease;
  }
`;

const MainContent = styled.div`
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};
  margin-left: 16%;

  @media (max-width: 890px) {
    margin-left: 0%;
  }
`;

interface DashboardProps {
  isLoading?: boolean;
  isOverlay?: boolean;
  children: ReactNode;
}

const DashboardLayout: FC<DashboardProps> = (props) => {
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(true);

  const toggleMenu = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 890) {
        setSidebarVisible(false);
      } else {
        setSidebarVisible(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headerSections = [
    {
      heading: "",
      menuItems: [
        { icon: <FaHome size={"0.8em"} />, text: "Home", path: "" },
        {
          icon: <FaPlayCircle size={"0.8em"} />,
          text: "Videos",
          path: "videos",
        },
        {
          icon: <FaPlayCircle size={"0.8em"} />,
          text: "Categories",
          path: "category",
        },
      ],
    },
    {
      heading: "ANALYTICS",
      menuItems: [
        {
          icon: <FaRegChartBar size={"0.8em"} />,
          text: "Metrics",
          path: "metrics",
        },
      ],
    },
    {
      heading: "CONFIGURATION",
      menuItems: [
        {
          icon: <FaGears size={"0.8em"} />,
          text: "Setting",
          path: "settings",
        },
        {
          icon: <FaBell size={"0.8em"} />,
          text: "What's New",
          path: "updates",
        },
        {
          icon: <FaHeadset size={"0.8em"} />,
          text: "Support",
          path: "support",
        },
      ],
    },
  ];

  return (
    <DashboardContainer>
      <FixedSidebar className={props.isOverlay ? "overlay-visible" : ""}>
        <SideMenu
          sections={headerSections}
          visible={sidebarVisible as boolean}
          setVisible={toggleMenu}
        />
      </FixedSidebar>
      <MainContent>
        {props.isLoading ? null : (
          <Header
            style={props.isOverlay ? { filter: "blur(4px)" } : {}}
            onMenuClick={toggleMenu}
          />
        )}
        {props.children || <div style={{ minHeight: "100vh" }}></div>}
      </MainContent>
    </DashboardContainer>
  );
};

export default DashboardLayout;
