import React, { FC, useState } from "react";
import styled, { css } from "styled-components";
import { COLORS } from "../../assets/theme";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const commonInputStyles = css`
  color: ${(props) => props.theme.colors.text};
  font-size: 1rem;
  font-weight: 400;
  background-clip: padding-box;
  padding: 0.6rem 0.75rem;
  line-height: 1.5;
  display: flex;
  border-radius: 0.5rem;
  outline: none;
  background-color: transparent;
  border: 1px solid ${COLORS.borderColor};
  width: 100%;
  position: relative;
  transition: border-color 0.12s ease-in-out, box-shadow 0.12s ease-in-out;

  &::placeholder {
    color: ${COLORS.placeHolder};
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 165, 0, 0.25);
    outline: 0;
    border-color: ${(props) => props.theme.section.hover};
  }
`;

const StyledInput = styled.input`
  ${commonInputStyles}
`;

const StyledTextarea = styled.textarea`
  ${commonInputStyles}
`;

const StyledFileInput = styled.input.attrs({ type: "file" })`
  ${commonInputStyles}
  padding: 0;
  &::file-selector-button {
    background-color: ${COLORS.borderColor};
    border: 0;
    padding: 0.6rem 0.75rem;
  }
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  height: 17px;
  width: 17px;
  padding: 0;

  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.42);
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: solid;
    border-color: ${(props) => props.theme.section.hover};
  }

  &:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
    background-color: ${(props) => props.theme.section.hover};
    border-color: transparent;
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
`;

const StyledPasswordToggle = styled.button`
  background: none;
  border: none;
  padding: 0.6rem 0.75rem;
  cursor: pointer;
  position: absolute;
  right: 0px; 
  top: 52%;
  transform: translateY(-50%);
  outline: none;
`;

const FieldContainer = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: "text" | "textarea" | "file" | "checkbox" | string;
  onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  isSecure?: boolean;
}

const Input: FC<InputProps> = ({ type, onFileChange, checked, isSecure, ...rest }) => {
  const isTextarea = type === "textarea";
  const isFile = type === "file";
  const isCheckBox = type === "checkbox";
  const isPassword = type === "password";

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FieldContainer>
      {isTextarea && (
        <StyledTextarea
          {...(rest as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
        />
      )}
      {isFile && (
        <StyledFileInput
          {...rest}
          onChange={
            onFileChange as (event: React.ChangeEvent<HTMLInputElement>) => void
          }
        />
      )}
      {!isTextarea && !isFile && !isSecure &&  (
        <StyledInput {...rest} type={type || "text"} />
      )}
      {isPassword && isSecure &&  (
        <>
          <StyledInput
            {...rest}
            type={showPassword ? "text" : "password"}
          />
          <StyledPasswordToggle
            type="button"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <FaEyeSlash size={17} color="#6A6C6E"/>
            ) : (
              <FaEye size={16} color="#6A6C6E"/>
            )}
          </StyledPasswordToggle>
        </>
      )}
      {isCheckBox && <StyledCheckbox {...rest} checked={checked} />}
    </FieldContainer>
  );
};

export default Input;
