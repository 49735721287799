import React, { FC, useState } from "react";
import styled from "styled-components";
import DashboardLayout from "../Layout";
import PageLayout from "../../components/organism/PageLayout";
import ListItem from "../../components/atoms/ListItem";
import Input from "../../components/atoms/Input";
import Button from "../../components/atoms/Btn";
import { COLORS } from "../../assets/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.1rem;
  border-radius: 10px;
  margin: 2em 0;
  background: ${(props) => props.theme.section.background};
`;

const ChangePassword: FC = (props) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  return (
    <DashboardLayout>
      <PageLayout title="Change Password">
        <Container>
          <ListItem label="Current password">
            <Input
              placeholder="Current password"
              type="password"
              isSecure={true}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </ListItem>
          <ListItem label="New password">
            <Input
              placeholder="New password"
              type="password"
              isSecure={true}
              value={newPassword}
              onChange={(e: any) => setNewPassword(e.target.value)}
            />
          </ListItem>
          <ListItem label="Confirm new password">
            <Input
              placeholder="Confirm new password"
              type="password"
              isSecure={true}
              value={confirmPassword}
              onChange={(e: any) => setConfirmPassword(e.target.value)}
            />
          </ListItem>
          <div style={{ marginTop: "2em" }}>
            <Button bgcolor={COLORS.secondary}>Change Password</Button>
          </div>
        </Container>
      </PageLayout>
    </DashboardLayout>
  );
};

export default ChangePassword;
