import React, {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import styled from "styled-components";
import {
  FaAngleDown,
  FaUserCircle,
  FaSignOutAlt,
  FaKey,
  FaAdjust,
  FaBars
} from "react-icons/fa";
import { ShimmerCircularImage } from "react-shimmer-effects";
import { useNavigate } from "react-router-dom";
import { COLORS, SPACING } from "../../assets/theme";
import { CustomThemeContext } from "../../context/ThemeContext";
import darkTheme from "../../styles/theme/dark";
import lightTheme from "../../styles/theme/light";
import Input from "../atoms/Input";
import Button from "../atoms/Btn";
import { authService } from "../../services/authService";

const HeaderContainer = styled.header`
  // background-color: ${(props) => props.theme.section.background};
  color: ${(props) => props.theme.colors.text};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;

  @media (max-width: 890px) {
    padding: 8px 0px;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  .icon {
    margin-bottom: 0;
    max-height: 40px;
    max-width: 40px;
    margin-left: 30px;
  }
`;

const ProfileIcon = styled.img<{ loaded: boolean }>`
  display: ${(props) => (props.loaded ? "block" : "none")};
  width: 40px;
  height: 40px;
  will-change: opacity;
  transform: translateZ(0);
  transition: opacity .25s linear;
  border-radius: 50%;
  margin-left: 30px;
`;

const MenuToggle = styled.div`
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
  color: ${COLORS.secondary};

  @media (min-width: 890px) {
    display: none;
  }
`;

const Dropdown = styled.div<{ isopen: boolean }>`
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  display: ${({ isopen }) => (isopen ? "block" : "none")};
  z-index: 999;
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 12px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    color: ${COLORS.white};
    background-color: ${(props) => props.theme.colors.text};
  }
`;

const Header: FC<{ onMenuClick: () => void, style?: React.CSSProperties }> = ({ onMenuClick, style }) => {
  const themeContext = useContext(CustomThemeContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLImageElement>(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropDownMenu = [
    {
      text: "Edit account details",
      icon: <FaUserCircle size={"1em"} />,
      path: "/account",
    },
    { text: "Change Password", icon: <FaKey size={"1em"} />, path: "/change-password" },
    { text: "Switch Theme", icon: <FaAdjust size={"1em"} />, path: "theme" },
    { text: "Logout", icon: <FaSignOutAlt size={"1em"} />, path: "/login" },
  ];

  const themeSlection = useCallback(() => {
    themeContext.setNewTheme(
      themeContext.currentTheme.updatedTheme.themeType === "dark"
        ? lightTheme
        : darkTheme
    );
  }, [themeContext]);

  const handleMenuClick = (item: string) => {
    if (item === "theme") {
      themeSlection();
    } else {
      if (item === "/login") authService.logout()
      navigate(item);
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    const handleDocumentClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <HeaderContainer style={style}>
      <MenuToggle onClick={onMenuClick}><FaBars/></MenuToggle>
      <Input
        type="text"
        placeholder=" 🔍 Search Steamingly..."
        style={{ width: "75%" }}
      />
      <Button bgcolor={COLORS.secondary}>Connect</Button>
      <ProfileContainer ref={dropdownRef} onClick={toggleDropdown}>
        {!isLoaded && <ShimmerCircularImage className="icon" />}
        <ProfileIcon
          src="https://xsgames.co/randomusers/avatar.php?g=pixel"
          alt="Profile"
          loading="lazy"
          loaded={isLoaded}
          onLoad={() => setIsLoaded(true)}
          onError={() => setIsLoaded(false)}
        />
        <FaAngleDown color={COLORS.secondary} scale={SPACING.xl} />
      </ProfileContainer>
      <Dropdown isopen={isDropdownOpen as boolean}>
        {dropDownMenu.map((item, index) => (
          <DropdownItem
            as="div"
            key={index}
            onClick={() => handleMenuClick(item.path)}

          >
            {item.icon}
            {item.text}
          </DropdownItem>
        ))}
      </Dropdown>
    </HeaderContainer>
  );
};

export default Header;
