import React, { FC } from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { authService } from "../services/authService";

type MiddlewareRouteProps = RouteProps & {
  requireAuth?: boolean;
  element?: any
};

const MiddlewareRoute: FC<MiddlewareRouteProps> = ({
  requireAuth = false,
  element
}) => {
  if (requireAuth && !authService.isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
      element
  );
};

export default MiddlewareRoute;
