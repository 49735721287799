import React, { FC, useEffect, useRef, useState } from "react";
import DashboardLayout from "./Layout";
import CustomCard from "../components/Cards/CustomCard";
import { FaBroadcastTower, FaLock, FaVideo } from "react-icons/fa";
import styled from "styled-components";
import PageLayout from "../components/organism/PageLayout";
import { HttpService } from "../services";

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
interface StatsResponseProps {
  total_videos: number;
  private_videos: number;
  public_videos: number;
}
interface APIResponseProps {
  success: boolean;
  data: StatsResponseProps;
  message: string;
}

const Home: FC = (props) => {
  const [states, setStates] = useState<StatsResponseProps | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const hasFetched = useRef(false);

  const fetchStatistics = async () => {
    try {
      const response = await HttpService.get<APIResponseProps>(
        `/dashboard_statistics`
      );
      if (response.data.success) {
        setStates(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if(!hasFetched.current && loading) fetchStatistics(); hasFetched.current = true;
  }, [loading]);

  return (
    <DashboardLayout>
      <PageLayout title="Hi">
        <Row>
          <CustomCard 
          title="Total Videos" 
          icon={<FaVideo />} 
          value={states?.total_videos ?? -1} 
          />
          <CustomCard 
          title="Total Private Videos" 
          icon={<FaLock />} 
          value={states?.private_videos ?? -1} 
          />
          <CustomCard
            title="Total Public Videos"
            icon={<FaBroadcastTower />}
            value={states?.public_videos ?? -1} 
          />
        </Row>
      </PageLayout>
    </DashboardLayout>
  );
};

export default Home;
