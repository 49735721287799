import React from "react";
import styled from "styled-components";

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 1rem 0;
  flex-wrap: wrap;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1;
`;

const ChildContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

interface PageLayoutProps {
  heading: string;
  subHeading?: string;
  children?: React.ReactNode;
}

const HeaderLayout: React.FC<PageLayoutProps> = ({
  heading,
  subHeading,
  children,
}) => {    
  return (
    <TopContainer>
      <Content>
        <h2>{heading}</h2>
        <h4>{subHeading}</h4>
      </Content>
      <ChildContainer>{children}</ChildContainer>
    </TopContainer>
  );
};

export default HeaderLayout;
